import React from 'react';

const MaterialsTable = ({ materials }) => {
  return (
    <table className="materials-table">
      <thead>
        <tr>
          <th>Código</th>
          <th>Descrição</th>
          <th>Unidade</th>
          <th>Origem do Preço</th>
          <th>Preço Unitário (R$)</th>
        </tr>
      </thead>
      <tbody>
        {materials.map((material) => (
          <tr key={material.codigo}>
            <td>{material.codigo}</td>
            <td>{material.descricao_do_insumo}</td>
            <td>{material.unidade_de_medida}</td>
            <td>{material.origem_do_preco}</td>
            <td>{material.preco_mediano_r}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default MaterialsTable;
