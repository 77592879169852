import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DataTables from './components/ServicesTable';
import MaterialsTable from './components/MaterialsTable';
import { filterItems } from './components/FilterData';

import {Adsense} from '@ctrl/react-adsense';


import './App.css';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000/'; // Or your actual API URL

const App = () => {
  const [baseData, setBaseData] = useState([]);
  const [materialsData, setMaterialsData] = useState([]);
  const [expandedServices, setExpandedServices] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 300;

  const [filterText, setFilterText] = useState('');
  const [filteredServices, setFilteredServices] = useState([]);
  const [filteredMaterials, setFilteredMaterials] = useState([]);
  const [showingServices, setShowingServices] = useState(false);
  const [isLoadingServices, setIsLoadingServices] = useState(true);

  // Fetch materials first
  useEffect(() => {
    const fetchMaterials = async () => {
      try {
        const response = await axios.get(`${API_URL}api/materials/`);
        setMaterialsData(response.data);
        setFilteredMaterials(response.data);
      } catch (error) {
        console.error('Error fetching materials data:', error);
      }
    };
    fetchMaterials();
  }, []); // Empty dependency array ensures this runs only once on mount

  // Fetch services after materials
  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await axios.get(`${API_URL}api/services/`);
        setBaseData(response.data);
        setFilteredServices(response.data);
        setIsLoadingServices(false);
      } catch (error) {
        console.error('Error fetching services data:', error);
        setIsLoadingServices(false);
      }
    };
    fetchServices();
  }, []); // Empty dependency array ensures this runs only once on mount

  useEffect(() => {
    const filtered = filterItems(filterText, baseData, materialsData, showingServices);
    setCurrentPage(1);
    if (showingServices) {
      setFilteredServices(filtered);
    } else {
      setFilteredMaterials(filtered);
    }
  }, [filterText, baseData, materialsData, showingServices]);

  const handleInputChange = (e) => {
    setFilterText(e.target.value);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const totalPages = Math.ceil((showingServices ? filteredServices : filteredMaterials).length / itemsPerPage);
  const currentItems = (showingServices ? filteredServices : filteredMaterials).slice(indexOfFirstItem, indexOfLastItem);

  const toggleExpandService = (uniqueIndex) => {
    setExpandedServices((prev) => ({
      ...prev,
      [uniqueIndex]: !prev[uniqueIndex],
    }));
  };

  return (
    <div>
      <div className="fixed-header">
        <div className="header-content">
          <div className="header-left">

          </div>
          <div className="header-center">
            <h1>Consulta SINAPI</h1>
            <div className="button-group">
              <button
                className={!showingServices ? 'active' : ''}
                onClick={() => {
                  setShowingServices(false);
                  setFilterText('');
                }}
              >
                Insumos
              </button>
              <button
                className={showingServices ? 'active' : ''}
                onClick={() => {
                  setShowingServices(true);
                  setFilterText('');
                }}
                disabled={isLoadingServices || baseData.length === 0}
                style={{
                  backgroundColor: isLoadingServices || baseData.length === 0 ? '#d3d3d3' : '',
                  color: isLoadingServices || baseData.length === 0 ? '#999' : '',
                  cursor: isLoadingServices || baseData.length === 0 ? 'not-allowed' : 'pointer',
                }}
              >
                Composições
              </button>
            </div>
            <div className="center-input-container">
              <input
                type="text"
                value={filterText}
                placeholder="Escreva os termos para filtrar"
                onChange={handleInputChange}
              />
            </div>
          </div>

          {/* Right section for the Ad */}
          <div className="header-right">
            <Adsense
              style={{ display: 'block', minWidth: '300px', maxHeight: '350px', maxWidth: '50vw', margin: 'auto' }}
              client="ca-pub-2344773042588967"
              slot="1408413103"
              data-ad-format="rectangle, horizontal"
              data-full-width-responsive="true"
            />
          </div>
        </div>
        
        <div className="pagination">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              onClick={() => setCurrentPage(index + 1)}
              className={currentPage === index + 1 ? 'active' : ''}
            >
              {index + 1}
            </button>
          ))}
        </div>
        <div>Resultados encontrados: {showingServices ? filteredServices.length : filteredMaterials.length}</div>
      </div>

      <div className="table-container">
        {showingServices ? (
          <DataTables
            services={currentItems.map((service) => ({
              ...service,
              uniqueIndex: service.codigo_da_composicao,
            }))}
            expandedServices={expandedServices}
            toggleExpandService={toggleExpandService}
            baseData={baseData}
          />
        ) : (
          <MaterialsTable materials={currentItems} />
        )}
      </div>
    </div>
  );
};

export default App;
