import React from 'react';

const DataTables = ({ services, onContextMenu, expandedServices, toggleExpandService, baseData }) => {
  const toNumber = (str) => parseFloat(str.replace(/\./g, '').replace(',', '.'));

  const calculateHours = (service) => {
    const componentH = service.components?.filter(c => c.unidade_item === 'H');
    if (!componentH || componentH.length === 0) return 0;

    const highestCoef = Math.max(...componentH.map(c => toNumber(c.coeficiente)));
    return highestCoef;
  };

  return (
    <table className="composition">
      <thead>
        <tr>
          <th>Expand</th>
          <th>Código</th>
          <th>Descrição</th>
          <th>Unidade</th>
          <th>Preço Unitário</th>
          <th>Tempo Unitário</th>
        </tr>
      </thead>
      <tbody className="composition">
        {services.map((service, index) => (
          <React.Fragment key={`${service.codigo_da_composicao}-${index}` } >
            <tr onContextMenu={(e) => onContextMenu(e, service)}>
              <td onClick={() => toggleExpandService(`${service.codigo_da_composicao}-${index}`)} style={{ cursor: 'pointer', textAlign: 'center', fontSize: '20px' }}>
                {expandedServices[`${service.codigo_da_composicao}-${index}`] ? '−' : '+'}
              </td>
              <td>{service.codigo_da_composicao}</td>
              <td>{service.descricao_da_composicao}</td>
              <td>{service.unidade}</td>
              <td>{toNumber(service.custo_total).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
              <td>{(calculateHours(service) || 0).toFixed(2).replace('.', ',')}</td>
            </tr>

            {expandedServices[`${service.codigo_da_composicao}-${index}`] && (
              <tr>
                <td colSpan="6">
                  <ComponentTable
                    components={service.components}
                    toggleExpandService={toggleExpandService}
                    expandedServices={expandedServices}
                    baseData={baseData}
                    parentCoeficiente={1} // Assuming parent coeficiente is 1 for main services
                    parentIndex={`${service.codigo_da_composicao}-${index}`}
                  />
                </td>
              </tr>
            )}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
};

const ComponentTable = ({ components, toggleExpandService, expandedServices, baseData, parentIndex, parentCoeficiente }) => {
  const toNumber = (str) => parseFloat(str.replace(/\./g, '').replace(',', '.'));

  return (
    <table className="component-table">
      <thead>
        <tr>
          <th>Expand</th>
          <th>Tipo</th>
          <th>Código</th>
          <th>Descrição</th>
          <th>Coeficiente</th>
          <th>Unidade</th>
          <th>Preço Unitário</th>
          <th>Preço Total</th>
        </tr>
      </thead>
      <tbody>
        {components?.map((component, idx) => {
          const coeficiente = toNumber(component.coeficiente); // Default to 1 if coeficiente doesn't exist
          const adjustedTotal = toNumber(component.preco_unitario) * coeficiente * parentCoeficiente; // Total is the same as adjusted price for now

          return (
            <React.Fragment key={`${parentIndex}-${component.codigo_item}-${idx}`}>
              <tr>
                <td style={{ textAlign: 'center' }}>
                  {component.tipo_item === 'COMPOSICAO' && (
                    <span onClick={() => toggleExpandService(`${parentIndex}-${component.codigo_item}-${idx}`)} style={{ cursor: 'pointer', fontSize: '20px' }}>
                      {expandedServices[`${parentIndex}-${component.codigo_item}-${idx}`] ? '−' : '+'}
                    </span>
                  )}
                </td>
                <td>{component.tipo_item}</td>
                <td>{component.codigo_item}</td>
                <td>{component.descrio_item}</td>
                <td>{coeficiente}</td>
                <td>{component.unidade_item}</td>
                <td>{component.preco_unitario}</td>
                <td>{adjustedTotal.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
              </tr>

              {/* Check if component has subcomponents and if expanded */}
              {component.tipo_item === 'COMPOSICAO' && expandedServices[`${parentIndex}-${component.codigo_item}-${idx}`] && (
                <tr>
                  <td colSpan="8">
                    {baseData && (
                      <ComponentTable
                        components={baseData.filter(item => item.codigo_da_composicao === component.codigo_item)[0]?.components || []}
                        toggleExpandService={toggleExpandService}
                        expandedServices={expandedServices}
                        baseData={baseData}
                        parentIndex={`${parentIndex}-${component.codigo_item}-${idx}`}
                        parentCoeficiente={coeficiente * parentCoeficiente} // Multiply by current component's coeficiente for subsequent levels
                      />
                    )}
                  </td>
                </tr>
              )}
            </React.Fragment>
          );
        })}
      </tbody>
    </table>
  );
};



export default DataTables;
